<script>
import { mapActions, mapGetters } from 'vuex';
import { DHME_PROJECT_INTAKE } from '@/modules/modules';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import AntLoading from '@/components/AntLoading.vue';

const store = 'dhmeProjectIntakeStore';

export default {
  name: 'DhmeProjectIntake',
  components: { AntLoading, ModuleNavigationBar },
  data: () => ({
    listIndex: 0,
  }),
  computed: {
    ...mapGetters({
      selectedProject: 'dhmeProjectIntakeStore/selectedProject',
      selectedProjectMetadata: 'dhmeProjectIntakeStore/selectedProjectMetadata',
      isLoading: 'dhmeProjectIntakeStore/isLoading',
      firm: 'dhmeProjectIntakeStore/firm',
      isDaiwaLicense: 'isDaiwaLicense',
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.listIndex = this.routeToIndex(newRoute.path);
      },
    },
  },
  async mounted() {
    const { projectId } = this.$route.params;
    await this.loadTablesIfNotExists();
    if (projectId) {
      await this.selectProject(projectId);
    }

    if (this.listIndex === -1) {
      this.listIndex = 0;
    }
  },
  methods: {
    DHME_PROJECT_INTAKE() {
      return DHME_PROJECT_INTAKE;
    },
    ...mapActions({
      toggleConcept: 'dhmeProjectIntakeStore/toggleConcept',
      selectProject: 'dhmeProjectIntakeStore/selectProject',
      loadTablesIfNotExists: 'dhmeProjectIntakeStore/loadTablesIfNotExists',
    }),
    routeToIndex(path) {
      if (path.endsWith('/info')) return 0;
      if (path.endsWith('/client')) return 1;
      if (path.endsWith('/layout')) return 2;
      if (path.endsWith('/risks')) return 3;
      if (path.endsWith('/planning')) return 4;
      if (path.endsWith('/roles')) return 5;
      return 0;
    },
  },
};
</script>
<template>
  <div class="d-flex flex-column">
    <module-navigation-bar title="Project intake">
      <template #module-nav-logo>
        <img
          v-if="isDaiwaLicense"
          alt=""
          height="40px"
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
        />
      </template>
    </module-navigation-bar>
    <div class="d-flex flex-row flex-1 flex-scroll-height">
      <div
        class="d-flex flex-column full-height background-white ant-border-right"
        style="width: 300px"
      >
        <div
          v-if="selectedProject && selectedProjectMetadata"
          class="flex-1 d-flex flex-column"
        >
          <v-list>
            <v-subheader>Project</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-tooltip right>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        @click="
                          $router.push({
                            name: `${DHME_PROJECT_INTAKE()}-overview`,
                          })
                        "
                        v-on="on"
                      >
                        mdi-chevron-left
                      </v-icon>
                    </template>
                    <span>To concepts and projects</span>
                  </v-tooltip>
                  {{ selectedProject.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-simple-checkbox
                :disabled="isLoading"
                :label="`Mark as concept`"
                :value="!!selectedProjectMetadata['is_concept']"
                dense
                hide-details
                @click="toggleConcept"
              />
              Markeer als concept
              <v-spacer />
              <v-chip
                :color="
                  selectedProjectMetadata['is_concept'] ? 'warning' : 'primary'
                "
                class="mr-2"
                small
                >{{
                  selectedProjectMetadata['is_concept'] ? 'concept' : 'project'
                }}
              </v-chip>
            </v-list-item>
          </v-list>
          <v-divider />
          <v-list>
            <v-subheader>Menu</v-subheader>
            <v-list-item-group v-model="listIndex" color="primary">
              <v-list-item @click="$router.push('./info')">
                Algemene Info
              </v-list-item>
              <v-list-item
                v-if="isDaiwaLicense"
                @click="$router.push('./client')"
              >
                Opdrachtgever
              </v-list-item>
              <v-list-item @click="$router.push('./layout')">
                Project Indeling
              </v-list-item>
              <v-list-item @click="$router.push('./risks')">
                Risico's
              </v-list-item>
              <v-list-item @click="$router.push('./planning')">
                Planning
              </v-list-item>
              <v-list-item @click="$router.push('./roles')">
                Rollen
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
        <div v-else class="flex-1 d-flex align-center justify-center">
          <ant-loading />
        </div>
      </div>
      <div
        v-if="selectedProject && selectedProjectMetadata"
        class="overflow-y-auto d-flex flex-column full-width"
      >
        <v-progress-linear
          v-show="isLoading"
          color="primary"
          height="5px"
          indeterminate
        />
        <transition mode="out-in" name="fade">
          <router-view class="pa-5" />
        </transition>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
